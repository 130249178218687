import React, { ReactNode } from 'react';
import { getSiteCssVariables } from '@wix/tpa-style-webpack-plugin/standalone';
import { IStyle } from '@wix/yoshi-flow-editor';

interface StaticThemeProps {
  name: string;
  style: IStyle;
  children: ReactNode;
}

export const StaticTheme: React.FC<StaticThemeProps> = ({ name, style, children }) => {
  const siteCssVariables = getSiteCssVariables(style.siteColors, style.siteTextPresets);
  return <div style={siteCssVariables.variables}>{children}</div>;
};
