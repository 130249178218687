import { useCallback, useEffect, useState } from 'react';
import { useBreakpoints } from '../../../../hooks/useBreakpoints';
import { useLogger } from '../../../../hooks/useLogger';
import { usePrevious } from '../../../../hooks/usePrevious';
import { Steps, Step, StepsState } from './types';

const initialStepsState: StepsState = {
  [Step.Login]: 'active',
  [Step.CustomerInfo]: 'active',
  [Step.AdditionalInfo]: 'pending',
  [Step.Payment]: 'pending',
};

export const useStepsState = (steps: Steps) => {
  const logger = useLogger();
  const prevSteps = usePrevious(steps);
  const { isNarrow } = useBreakpoints();
  const [stepsState, setStepsState] = useState<StepsState>(initialStepsState);
  const [currentStep, setCurrentStep] = useState(0);
  const isCurrentStepLastOne = currentStep === steps.length - 1;

  const setActive = useCallback(
    (position: number) => {
      const newStepsState: StepsState = { ...initialStepsState };
      steps.forEach((step, index) => {
        if (index < position) {
          newStepsState[step] = 'completed';
        } else if (index === position) {
          newStepsState[step] = 'active';
        } else {
          newStepsState[step] = 'pending';
        }
      });
      setStepsState(newStepsState);

      setCurrentStep(position);
    },
    [steps, stepsState],
  );

  const setCompleted = useCallback(
    (position: number) => {
      const newStepsState: StepsState = { ...stepsState };
      newStepsState[steps[position]] = 'completed';
      if (steps[position + 1]) {
        newStepsState[steps[position + 1]] = 'active';
      }
      setStepsState(newStepsState);

      if (steps[position + 1]) {
        setCurrentStep(position + 1);
      }
    },
    [steps, stepsState],
  );

  useEffect(() => {
    if (prevSteps?.length) {
      if (steps.length > prevSteps.length) {
        logger.log('useStepState - added step');
        const position = currentStep;
        const newStepsState: StepsState = { ...initialStepsState };
        steps.forEach((step, index) => {
          if (index < position) {
            newStepsState[step] = 'completed';
          } else if (index === position) {
            newStepsState[step] = stepsState[steps[position]];
          } else if (index === position + 1 && stepsState[steps[currentStep]] === 'completed') {
            newStepsState[step] = 'active';
          } else {
            newStepsState[step] = 'pending';
          }
        });
        setStepsState(newStepsState);
        setCurrentStep(position);
      } else if (steps.length < prevSteps.length) {
        logger.log('useStepState - removed step');
        if (currentStep >= steps.length) {
          logger.log('useStepState - adjusting state');
          const position = steps.length - 1;
          const newStepsState: StepsState = { ...initialStepsState };
          steps.forEach((step, index) => {
            if (index < position) {
              newStepsState[step] = 'completed';
            } else if (index === position) {
              newStepsState[step] = isNarrow ? stepsState[steps[position]] : 'active';
            } else {
              newStepsState[step] = 'pending';
            }
          });
          setStepsState(newStepsState);
          setCurrentStep(position);
        }
      }
    }
  }, [currentStep, steps, prevSteps, stepsState]);

  return {
    stepsState,
    currentStep,
    isCurrentStepLastOne,
    setActive,
    setCompleted,
  };
};
