import React from 'react';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { AppWidgetProps, ModalType } from '../../../types/common';
import { openBillingPage } from '../../../utils/openBillingPage';
import { AlertModal } from './AlertModal';

const LazyUpgradeModal = loadable(() => import('./UpgradeModal'));

export const Modal: React.FC<AppWidgetProps> = ({ modal, metaSiteId, biUpgradeReferralClick }) => {
  const onUpgrade = React.useCallback(() => {
    biUpgradeReferralClick('checkout-upgrade-modal');
    metaSiteId && openBillingPage(metaSiteId);
  }, [biUpgradeReferralClick, metaSiteId]);

  if (modal.type === ModalType.None) {
    return null;
  }

  const { type, onClose } = modal;

  switch (type) {
    case ModalType.Upgrade:
      return <LazyUpgradeModal onClose={onClose} onPreview={onClose} onUpgrade={onUpgrade} />;
    default:
      return <AlertModal {...modal} />;
  }
};
