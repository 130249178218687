/*
| Theme key | Preset key | Editor element |
|-----------|------------|----------------|
| font_0    | Title      | Heading 1      |
| font_1    | Menu       | -              |
| font_2    | Page-title | Heading 2      |
| font_3    | Heading-XL | Heading 3      |
| font_4    | Heading-L  | Heading 4      |
| font_5    | Heading-M  | Heading 5      |
| font_6    | Heading-S  | Heading 6      |
| font_7    | Body-L     | Paragraph 1    |
| font_8    | Body-M     | Paragraph 2    |
| font_9    | Body-S     | Paragraph 3    |
| font_10   | Body-XS    | -              |
*/

export const STYLE_PARAMS = {
  colors: {},
  numbers: {},
  booleans: {},
  fonts: {},
  googleFontsCssUrl: '',
  uploadFontFaces: '',
};

export const SITE_COLORS = [
  { name: 'color_1', value: '#FFFFFF', reference: 'white/black' },
  { name: 'color_2', value: '#000000', reference: 'black/white' },
  { name: 'color_3', value: '#ED1C24', reference: 'primery-1' },
  { name: 'color_4', value: '#0088CB', reference: 'primery-2' },
  { name: 'color_5', value: '#FFCB05', reference: 'primery-3' },
  { name: 'color_11', value: '#FFFFFF', reference: 'color-1' },
  { name: 'color_12', value: '#CCCCCC', reference: 'color-2' },
  { name: 'color_13', value: '#969696', reference: 'color-3' },
  { name: 'color_14', value: '#646464', reference: 'color-4' },
  { name: 'color_15', value: '#000000', reference: 'color-5' },
  { name: 'color_16', value: '#B5BCF0', reference: 'color-6' },
  { name: 'color_17', value: '#8F98E2', reference: 'color-7' },
  { name: 'color_18', value: '#000000', reference: 'color-8' },
  { name: 'color_19', value: '#25318D', reference: 'color-9' },
  { name: 'color_20', value: '#131946', reference: 'color-10' },
  { name: 'color_21', value: '#F9C5B4', reference: 'color-11' },
  { name: 'color_22', value: '#F3A78F', reference: 'color-12' },
  { name: 'color_23', value: '#ED5829', reference: 'color-13' },
  { name: 'color_24', value: '#9E3B1B', reference: 'color-14' },
  { name: 'color_25', value: '#4F1D0E', reference: 'color-15' },
  { name: 'color_26', value: '#D2ACF7', reference: 'color-16' },
  { name: 'color_27', value: '#BA83F0', reference: 'color-17' },
  { name: 'color_28', value: '#8015E8', reference: 'color-18' },
  { name: 'color_29', value: '#550E9B', reference: 'color-19' },
  { name: 'color_30', value: '#2B074D', reference: 'color-20' },
  { name: 'color_31', value: '#B1D3BB', reference: 'color-21' },
  { name: 'color_32', value: '#7FA88B', reference: 'color-22' },
  { name: 'color_33', value: '#407C51', reference: 'color-23' },
  { name: 'color_34', value: '#2B5336', reference: 'color-24' },
  { name: 'color_35', value: '#15291B', reference: 'color-25' },
];

export const SITE_TEXT_PRESETS = {
  Title: {
    editorKey: 'font_0',
    lineHeight: '40px',
    style: 'normal',
    weight: '400',
    size: '32px',
    fontFamily: '"Avenir Next", HelveticaNeueW02-45Ligh, Arial, sans-serif',
    value: 'font:normal normal 400 32px/40px "Avenir Next", HelveticaNeueW02-45Ligh, Arial, sans-serif;',
  },
  Menu: {
    editorKey: 'font_1',
    lineHeight: '1.4em',
    style: 'normal',
    weight: 'normal',
    size: '16px',
    fontFamily: 'din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif',
    value: 'font:normal normal normal 16px/1.4em din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif;',
  },
  'Page-title': {
    editorKey: 'font_2',
    lineHeight: '32px',
    style: 'normal',
    weight: '400',
    size: '24px',
    fontFamily: '"Avenir Next", HelveticaNeueW02-45Ligh, Arial, sans-serif',
    value: 'font:normal normal 400 24px/32px "Avenir Next", HelveticaNeueW02-45Ligh, Arial, sans-serif;',
  },
  'Heading-XL': {
    editorKey: 'font_3',
    lineHeight: '1.4em',
    style: 'normal',
    weight: 'normal',
    size: '32px',
    fontFamily: '"Avenir Next", HelveticaNeueW02-45Ligh, Arial, sans-serif',
    value: 'font:normal normal normal 32px/1.4em "Avenir Next", HelveticaNeueW02-45Ligh, Arial, sans-serif;',
  },
  'Heading-L': {
    editorKey: 'font_4',
    lineHeight: '1.4em',
    style: 'normal',
    weight: 'normal',
    size: '28px',
    fontFamily: '"Avenir Next", HelveticaNeueW02-45Ligh, Arial, sans-serif',
    value: 'font:normal normal normal 28px/1.4em "Avenir Next", HelveticaNeueW02-45Ligh, Arial, sans-serif;',
  },
  'Heading-M': {
    editorKey: 'font_5',
    lineHeight: '1.4em',
    style: 'normal',
    weight: 'normal',
    size: '25px',
    fontFamily: '"Avenir Next", HelveticaNeueW02-45Ligh, Arial, sans-serif',
    value: 'font:normal normal normal 25px/1.4em "Avenir Next", HelveticaNeueW02-45Ligh, Arial, sans-serif;',
  },
  'Heading-S': {
    editorKey: 'font_6',
    lineHeight: '1.4em',
    style: 'normal',
    weight: 'normal',
    size: '22px',
    fontFamily: '"Avenir Next", HelveticaNeueW02-45Ligh, Arial, sans-serif',
    value: 'font:normal normal normal 22px/1.4em "Avenir Next", HelveticaNeueW02-45Ligh, Arial, sans-serif;',
  },
  'Body-L': {
    editorKey: 'font_7',
    lineHeight: '1.4em',
    style: 'normal',
    weight: 'normal',
    size: '17px',
    fontFamily: '"Avenir Next", HelveticaNeueW02-45Ligh, Arial, sans-serif',
    value: 'font:normal normal normal 17px/1.4em "Avenir Next", HelveticaNeueW02-45Ligh, Arial, sans-serif;',
  },
  'Body-M': {
    editorKey: 'font_8',
    lineHeight: '24px',
    style: 'normal',
    weight: '400',
    size: '16px',
    fontFamily: 'Avenir, HelveticaNeueW02-45Ligh, Arial, sans-serif',
    value: 'font:normal normal 400 16px/24px Avenir, HelveticaNeueW02-45Ligh, Arial, sans-serif;',
  },
  'Body-S': {
    editorKey: 'font_9',
    lineHeight: '1.4em',
    style: 'normal',
    weight: 'normal',
    size: '14px',
    fontFamily: '"Avenir Next", HelveticaNeueW02-45Ligh, Arial, sans-serif',
    value: 'font:normal normal normal 14px/1.4em "Avenir Next", HelveticaNeueW02-45Ligh, Arial, sans-serif;',
  },
  'Body-XS': {
    editorKey: 'font_10',
    lineHeight: '1.4em',
    style: 'normal',
    weight: 'normal',
    size: '12px',
    fontFamily: 'din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif',
    value: 'font:normal normal normal 12px/1.4em din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif;',
  },
};

export const style = {
  styleParams: STYLE_PARAMS,
  siteColors: SITE_COLORS,
  siteTextPresets: SITE_TEXT_PRESETS,
};
