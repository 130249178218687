import React, { ReactNode } from 'react';
import { style } from '../../../checkoutStyleTheme';
import { StaticTheme } from '../../StaticTheme';
import './CheckoutTheme.st.css';

export const CheckoutTheme: React.FC<{ children: ReactNode }> = ({ children }) => (
  <StaticTheme name="pricing-plans-checkout" style={style}>
    {children}
  </StaticTheme>
);
