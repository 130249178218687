import React from 'react';
import { CheckoutStyledButton } from '../CheckoutStyledButton';
import styles from './BuyNowButton.scss';

interface BuyNowButtonProps {
  onClick: () => void;
  label: string;
  dataHook?: string;
  loading?: boolean;
  disabled?: boolean;
}

export const BuyNowButton = React.forwardRef<HTMLButtonElement, BuyNowButtonProps>(
  ({ label, onClick, dataHook, loading, disabled }, ref) => (
    <CheckoutStyledButton
      ref={ref}
      className={styles.button}
      label={label}
      onClick={() => onClick()}
      dataHook={dataHook}
      loading={loading}
      disabled={loading || disabled}
    />
  ),
);
