import React, { Dispatch, ReactNode } from 'react';
import { dateToYMD } from '../../../../utils/date';

interface CheckoutState {
  startDate: string;
  isStartDateValid: boolean;
  isStartDateDisabled: boolean;
  isStartDateUpdated: boolean;
}

type CheckoutAction =
  | { type: 'setStartDate'; startDate: string; valid: boolean }
  | { type: 'setStartDateUpdated'; updated: boolean }
  | { type: 'setStartDateDisabled'; disabled: boolean };

function reducer(state: CheckoutState, action: CheckoutAction): CheckoutState {
  switch (action.type) {
    case 'setStartDate':
      return { ...state, startDate: action.startDate, isStartDateValid: action.valid };
    case 'setStartDateDisabled':
      return { ...state, isStartDateDisabled: action.disabled };
    case 'setStartDateUpdated':
      return { ...state, isStartDateUpdated: action.updated };
    default:
      return state;
  }
}

const CheckoutStateContext = React.createContext<[CheckoutState, Dispatch<CheckoutAction>]>(null!);

export function useCheckoutState() {
  return React.useContext(CheckoutStateContext);
}

export const CheckoutStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const stateAndReducer = React.useReducer(reducer, {
    startDate: dateToYMD(new Date()),
    isStartDateValid: false,
    isStartDateDisabled: false,
    isStartDateUpdated: false,
  });
  return <CheckoutStateContext.Provider value={stateAndReducer}>{children}</CheckoutStateContext.Provider>;
};
