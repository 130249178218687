import React from 'react';
import { useCheckoutLoader } from '../../../hooks/useCheckoutLoader';
import type { AppWidgetProps } from '../../../types/common';
import Checkout from '../../PackagePicker/Widget/Checkout';
import { CheckoutLoader } from '../../PackagePicker/Widget/CheckoutLoader';

const CheckoutWidget: React.FC<AppWidgetProps> = (props) => {
  const { isCheckoutDataInitialized } = props;
  const { shouldShowLoader, setIsCheckoutComponentLoaded } = useCheckoutLoader({ isCheckoutDataInitialized });

  return (
    <CheckoutLoader loading={shouldShowLoader}>
      <Checkout
        {...props}
        siteStyles={props.host.style}
        viewMode={props.host.viewMode}
        onLoad={() => setIsCheckoutComponentLoaded(true)}
      />
    </CheckoutLoader>
  );
};

export default CheckoutWidget;
