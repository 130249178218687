import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { useEnvironment } from '@wix/yoshi-flow-editor';

type Breakpoints = {
  [key: string]: (width: number) => boolean;
};

type BreakpointsState = {
  [key: string]: boolean;
};

type BreakpointsContextType = BreakpointsState | null;

const BreakpointsContext = createContext<BreakpointsContextType>(null);

export const useBreakpoints = (): BreakpointsState => {
  const breakpoints = useContext(BreakpointsContext);
  if (breakpoints === null) {
    throw new Error('useBreakpoints must be used within a BreakpointsProvider');
  }
  return breakpoints;
};

type BreakpointsProviderProps = {
  children: React.ReactNode;
  breakpoints: Breakpoints;
  defaultWidth: number;
};

const BreakpointsProvider = ({ children, breakpoints, defaultWidth }: BreakpointsProviderProps) => {
  const { isSSR } = useEnvironment();

  const containerRef = useRef<HTMLDivElement>(null);

  const [breakpointsState, setBreakpointsState] = useState<BreakpointsState>(() =>
    Object.keys(breakpoints).reduce((result, breakpointName) => {
      const matchBreakpoint = breakpoints[breakpointName];
      result[breakpointName] = matchBreakpoint(defaultWidth);
      return result;
    }, {} as BreakpointsState),
  );

  const handleResize = useCallback(
    ([entry]: ResizeObserverEntry[]) => {
      if (!entry) {
        return;
      }
      const newBreakpointsState = Object.keys(breakpoints).reduce((result, breakpointName) => {
        const matchBreakpoint = breakpoints[breakpointName];
        result[breakpointName] = matchBreakpoint(entry.contentRect.width);
        return result;
      }, {} as BreakpointsState);
      if (!isEqual(breakpointsState, newBreakpointsState)) {
        setBreakpointsState(newBreakpointsState);
      }
    },
    [breakpoints, breakpointsState],
  );

  useEffect(() => {
    if (containerRef.current === null) {
      return;
    }

    // Messy code to have happy tests
    // const resizeObserver = new ResizeObserver(handleResize);
    const resizeObserver = isSSR || !window.ResizeObserver ? null : new ResizeObserver(handleResize);
    resizeObserver?.observe(containerRef.current);
    return () => {
      resizeObserver?.disconnect();
    };
  }, [handleResize, isSSR]);

  return (
    <div ref={containerRef}>
      <BreakpointsContext.Provider value={breakpointsState}>{children}</BreakpointsContext.Provider>
    </div>
  );
};

export default BreakpointsProvider;
