import React from 'react';
import { useBreakpoints } from '../../../../../hooks/useBreakpoints';
import { BigSummary } from './Big';
import { SmallSummary } from './Small';
import { SummaryProps } from './types';

export const Summary: React.FC<SummaryProps> = (props) => {
  const { isNarrow } = useBreakpoints();

  return isNarrow ? <SmallSummary {...props} /> : <BigSummary {...props} />;
};
