import { PublicPlan, PeriodUnit } from '@wix/ambassador-pricing-plans-v2-plan/types';

export const EMPTY_PLAN: PublicPlan = {
  id: '',
  primary: false,
  perks: {
    values: [],
  },
  description: '',
  name: '',
  pricing: {
    price: {
      value: '',
      currency: '',
    },
    singlePaymentForDuration: {
      count: 0,
      unit: PeriodUnit.DAY,
    },
    singlePaymentUnlimited: false,
  },
};
