import React from 'react';
import addDays from 'date-fns/addDays';
import parse from 'date-fns/parseISO';
import { TextField } from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { classes } from './DateInput.st.css';
import { useStartDateInputs } from './useStartDateInputs';

export interface DateInputProps {
  value?: string;
  min?: string;
  max?: string;
  dataHook?: string;
  disabled?: boolean;
  onChange(value: string, valid: boolean): void;
  label?: string;
}

enum DateInputState {
  Valid,
  TooLate,
  TooSoon,
  OtherError,
}

export const DateInput: React.FC<DateInputProps> = ({ value, min, max, dataHook, disabled, onChange, label }) => {
  const { t } = useTranslation();
  const inputs = useStartDateInputs();
  const [state, setState] = React.useState(DateInputState.Valid);
  const ref = React.useRef<TextField>(null);

  React.useEffect(() => {
    // @ts-expect-error
    const input = ref.current.TextFieldRef.current.input;
    if (input) {
      inputs.add(input);
      return () => inputs.remove(input);
    }
  }, [inputs]);

  React.useEffect(() => {
    // @ts-expect-error
    const input = ref.current.TextFieldRef.current.input;
    if (input) {
      setState(toDateInputState(input.validity));
    }
  }, [value]);

  const revalidate = React.useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const { validity } = target;
      setState(toDateInputState(validity));
      onChange(target.value, validity.valid);
    },
    [onChange],
  );

  const error = toError(state);
  const maxDate = max ? parse(max) : null;

  return (
    <TextField
      label={label}
      className={classes.root}
      data-hook={dataHook}
      id={dataHook}
      value={value}
      min={min}
      max={max}
      onChange={revalidate}
      pattern="\d{4}-\d{2}-\d{2}"
      ref={ref}
      disabled={disabled}
      required
      type="date"
      error={Boolean(error)}
      errorMessage={
        error
          ? t(error, {
              minDate: min ? shortDate(parse(min)) : null,
              maxDate: maxDate && shortDate(maxDate),
              maxDatePlusOneDay: maxDate && shortDate(addDays(maxDate, 1)),
            })
          : undefined
      }
      newErrorMessage
    />
  );
};

function toError(state: DateInputState) {
  switch (state) {
    case DateInputState.OtherError:
      return 'payment.checkout-summary.start-date.unknown-error';
    case DateInputState.TooLate:
      return 'payment.checkout-summary.start-date.too-late';
    case DateInputState.TooSoon:
      return 'payment.checkout-summary.start-date.too-soon';
    default:
      return undefined;
  }
}

function toDateInputState(validity: ValidityState) {
  switch (true) {
    case validity.valid:
      return DateInputState.Valid;
    case validity.rangeOverflow:
      return DateInputState.TooLate;
    case validity.rangeUnderflow:
      return DateInputState.TooSoon;
    default:
      return DateInputState.OtherError;
  }
}

function shortDate(date: Date) {
  // According to MDN dateStyle is supported by IE11.
  const options = { dateStyle: 'short' } as Intl.DateTimeFormatOptions;
  return date.toLocaleString(undefined, options);
}
