import React from 'react';
import { FormViewerContext } from '@wix/form-viewer/widget';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import BreakpointsProvider from '../../../hooks/BreakpointsProvider';
import { LocaleProvider } from '../../../hooks/useLocale';
import type { AppWidgetProps } from '../../../types/common';
import { Modal } from '../../PackagePicker/Widget/Modal';
import CheckoutWidget from './CheckoutWidget';

const BREAKPOINTS = {
  isNarrow: (width: number) => width > 0 && width <= 750,
  isExtraNarrow: (width: number) => width > 0 && width <= 450,
};

export default function (props: AppWidgetProps) {
  const { isMobile } = useEnvironment();
  const width = props.host.dimensions.width ?? (isMobile ? 320 : 980);
  return (
    <BreakpointsProvider breakpoints={BREAKPOINTS} defaultWidth={width}>
      <LocaleProvider locale={props.locale}>
        <FormViewerContext {...props}>
          <CheckoutWidget {...props} />
        </FormViewerContext>
        <Modal {...props} />
      </LocaleProvider>
    </BreakpointsProvider>
  );
}
