import React, { forwardRef } from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { Check } from '@wix/wix-ui-icons-common/on-stage/';
import { useBreakpoints } from '../../../../../hooks/useBreakpoints';
import { useLocale } from '../../../../../hooks/useLocale';
import { st, classes } from './StepSection.st.css';

export interface SectionProps {
  dataHook: string;
  position: number;
  title: string;
  state: 'pending' | 'active' | 'completed';
  action?: React.ReactNode;
  children?: React.ReactNode;
}

export const StepSection = forwardRef<HTMLDivElement, SectionProps>(
  ({ dataHook, position, title, action, state, children }, ref) => {
    const { locale } = useLocale();
    const stepPosition = Number(position).toLocaleString(locale);

    const { isNarrow } = useBreakpoints();
    return (
      <div className={st(classes.root, { narrow: isNarrow, state })} data-hook={dataHook}>
        <header ref={ref} className={classes.header}>
          <Text className={classes.headerText} tagName="h2">
            <span>{state === 'completed' ? <Check /> : stepPosition}</span> {title}
          </Text>
          {action}
        </header>
        {children ? <div className={classes.content}>{children}</div> : null}
      </div>
    );
  },
);
