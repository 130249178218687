import { useMemo } from 'react';
import { Benefit } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { Order } from '@wix/ambassador-pricing-plans-v2-order/types';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useLocale } from '../../../../../hooks/useLocale';
import { FormattedSpannedPrice } from '../../../../../utils';
import { useMultilingualCountryCode } from '../useMultilingualCountryCode';
import { CheckoutSummaryPresenter } from './presenters';

interface UsePresenterProps {
  plan: PublicPlan;
  prices: FormattedSpannedPrice[];
  benefits: Benefit[];
  order?: Order;
}

export const usePresenter = ({ plan, prices, benefits, order }: UsePresenterProps) => {
  const { language } = useEnvironment();
  const { locale } = useLocale();
  const { multilingualCountryCode } = useMultilingualCountryCode();
  const { t } = useTranslation();

  const presenter = useMemo(
    () =>
      new CheckoutSummaryPresenter({
        plan,
        prices,
        benefits,
        locale,
        t,
        order,
        countryCode: multilingualCountryCode,
        language,
      }),
    [plan, prices, benefits, locale, t, order, multilingualCountryCode, language],
  );

  return presenter;
};
