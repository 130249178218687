import merge from 'lodash/merge';
import { Benefit, BenefitType } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { Order } from '@wix/ambassador-pricing-plans-v2-order/types';
import { PublicPlan, PeriodUnit } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { isRecurringPlan, isUnlimitedPlan, hasSetupFee } from '@wix/pricing-plans-utils';
import { getTaxName } from '@wix/pricing-plans-utils/tax-names';
import { TFunction } from '@wix/yoshi-flow-editor';
import { FormattedSpannedPrice } from '../../../../../utils';
import { EMPTY_PLAN } from './constants';

type PeriodStringType = 'Singular' | 'Plural' | 'Frequency';

interface BaseCheckoutSummaryPresenterProps {
  plan: PublicPlan;
  prices: FormattedSpannedPrice[];
  benefits: Benefit[];
  locale?: string;
  t: TFunction;
  language?: string;
  order?: Order;
  countryCode?: string;
}

const unitMap = {
  [PeriodUnit.DAY]: {
    Singular: 'day',
    Plural: 'days',
    Frequency: 'daily',
  },
  [PeriodUnit.WEEK]: {
    Singular: 'week',
    Plural: 'weeks',
    Frequency: 'weekly',
  },
  [PeriodUnit.MONTH]: {
    Singular: 'month',
    Plural: 'months',
    Frequency: 'monthly',
  },
  [PeriodUnit.YEAR]: {
    Singular: 'year',
    Plural: 'years',
    Frequency: 'yearly',
  },
} as const;

class BaseCheckoutSummaryPresenter {
  protected plan: PublicPlan;
  protected prices: FormattedSpannedPrice[];
  protected benefits: Benefit[];
  protected locale?: string;
  protected t: TFunction;
  protected order?: Order;
  protected language?: string;
  protected countryCode?: string;

  constructor({ plan, prices, benefits, locale, t, order, language, countryCode }: BaseCheckoutSummaryPresenterProps) {
    this.plan = merge({}, EMPTY_PLAN, plan);
    this.prices = prices;
    this.benefits = benefits;
    this.locale = locale;
    this.t = t;
    this.order = order;
    this.language = language;
    this.countryCode = countryCode;
  }

  protected getTax() {
    return this.prices[0]?.price?.tax ?? {};
  }

  protected getCycleLength() {
    return this.plan.pricing?.subscription?.cycleDuration?.count ?? undefined;
  }

  protected hasCustomCycles() {
    const length = this.getCycleLength();
    return !!length && length !== 1;
  }

  protected getPeriodUnit() {
    return this.plan.pricing?.subscription?.cycleDuration?.unit ?? this.plan.pricing?.singlePaymentForDuration?.unit;
  }

  protected getPlanDuration() {
    if (this.isRecurring()) {
      return (this.getCycleLength() ?? 1) * (this.plan.pricing?.subscription?.cycleCount ?? 1);
    }

    return this.plan.pricing?.singlePaymentForDuration?.count ?? undefined;
  }

  protected getFreeTrial() {
    return this.plan.pricing?.freeTrialDays;
  }

  protected hasSeveralPaymentCycles() {
    return this.prices.length > 1;
  }

  protected getFirstPaymentCycleLength() {
    if (this.hasSeveralPaymentCycles()) {
      return this.prices[0].duration?.numberOfCycles ?? undefined;
    }

    return undefined;
  }

  protected getFormatedTotal(totalIndex: number) {
    return this.prices[totalIndex]?.price?.formattedTotal;
  }

  protected isCouponForOneCycle() {
    if (!this.hasCoupon()) {
      return false;
    }

    if (hasSetupFee(this.plan)) {
      return !this.prices[1]?.price?.coupon;
    }

    return this.prices[0].duration?.numberOfCycles === 1;
  }

  protected isCouponForAllCycles() {
    if (!this.hasCoupon()) {
      return false;
    }

    if (hasSetupFee(this.plan)) {
      return Boolean(this.prices.length === 2 && this.prices[1]?.price?.coupon);
    }

    return this.prices.length === 1;
  }

  protected isFullyDiscounted() {
    if (!this.hasCoupon()) {
      return false;
    }

    if (hasSetupFee(this.plan)) {
      return Number(this.prices[1].price?.total) === 0;
    }

    return Number(this.prices[0].price?.total) === 0;
  }

  protected getPriceNumberOfCycles(index: number) {
    return this.prices[index]?.duration?.numberOfCycles ?? 0;
  }

  protected periodString(stringType: PeriodStringType) {
    const unit = this.getPeriodUnit();

    if (!unit || unit === PeriodUnit.UNDEFINED) {
      return '';
    }

    return unitMap[unit][stringType];
  }

  getPlanName() {
    return this.plan.name;
  }

  hasSessions() {
    return this.benefits ? this.benefits.length > 0 : false;
  }

  isRecurring() {
    return isRecurringPlan(this.plan);
  }

  hasCoupon() {
    return Boolean(this.prices[0]?.price?.coupon);
  }

  getTaxName() {
    return getTaxName(this.countryCode, this.language);
  }

  getTaxRate() {
    return this.getTax().rate;
  }

  showFreeTrial() {
    return Boolean(this.plan.pricing?.freeTrialDays);
  }

  isFreeTrialUnavailable() {
    return this.order && !this.order.freeTrialDays;
  }

  isFreeTrialAvailable() {
    return this.showFreeTrial() && !this.isFreeTrialUnavailable();
  }
}

export class CheckoutSummaryPresenter extends BaseCheckoutSummaryPresenter {
  private formatSummaryItem = (label: string, value: string) => `${label}: ${value}`;

  getSessionCount() {
    if (this.hasSessions()) {
      const benefit = this.benefits[0];
      if (benefit.benefitType === BenefitType.UNLIMITED) {
        return this.t('payment.checkout-summary.sessions-unlimited');
      } else {
        return Number(benefit.creditAmount).toLocaleString(this.locale ?? this.language);
      }
    }
  }

  getDuration() {
    if (isUnlimitedPlan(this.plan)) {
      return this.t('payment.checkout-summary.duration-unlimited');
    }

    const duration = this.getPlanDuration();

    if (duration === 1) {
      return this.t(`payment.checkout-summary.duration-1-${this.periodString('Singular')}`);
    } else {
      return this.t(`payment.checkout-summary.duration-n-${this.periodString('Plural')}`, { amount: duration });
    }
  }

  getFreeTrialDays() {
    const days = this.getFreeTrial();

    if (!days) {
      return this.t('payment.checkout-summary.trial-unavailable');
    }
    return days === 1
      ? this.t('payment.checkout-summary.trial.duration-1-day')
      : this.t('payment.checkout-summary.trial.duration-n-days', { amount: days });
  }

  getFreeTrialFormatted() {
    const freeTrialLabel = this.t('payment.checkout-summary.trial');
    if (this.isFreeTrialAvailable()) {
      return this.formatSummaryItem(freeTrialLabel, this.getFreeTrialDays());
    }

    return this.formatSummaryItem(
      freeTrialLabel,
      this.t('payment.checkout-summary.trial-unavailable-disclaimer-short'),
    );
  }

  getDurationFormatted() {
    return this.formatSummaryItem(this.t('payment.checkout-summary.duration'), this.getDuration());
  }

  getSessionFormatted() {
    const sessions = this.getSessionCount();

    if (!sessions) {
      return null;
    }

    return this.formatSummaryItem(this.t('payment.checkout-summary.sessions'), sessions.toString());
  }

  getPaymentPeriod() {
    if (!this.isRecurring()) {
      return null;
    }

    const discountedCycles = this.getFirstPaymentCycleLength();

    if (discountedCycles) {
      if (this.hasCustomCycles()) {
        return null;
      }

      return this.t('payment.checkout-summary.payment-period-limited-' + this.periodString('Singular'), {
        count: discountedCycles,
      });
    }

    return this.t('payment.checkout-summary.payment-period-' + this.periodString('Singular'), {
      count: this.getCycleLength() ?? 1,
    });
  }

  private getPaymentFrequency(): string | null {
    return this.t(`payment.checkout-summary.payment-disclaimer.cycle-${this.periodString('Frequency')}-plural`, {
      count: this.getCycleLength(),
    });
  }

  showPaymentPeriod() {
    if (!this.isRecurring()) {
      return false;
    }

    if (!hasSetupFee(this.plan) && (!this.showFreeTrial() || this.isFreeTrialUnavailable())) {
      if (!this.hasCoupon() || this.isCouponForAllCycles()) {
        return true;
      }
    }

    if (
      !hasSetupFee(this.plan) &&
      this.isFreeTrialAvailable() &&
      this.hasCoupon() &&
      this.isCouponForAllCycles() &&
      this.isFullyDiscounted()
    ) {
      return true;
    }

    return false;
  }

  getTotalLabel() {
    if (!this.isRecurring()) {
      return this.t('payment.checkout-summary.total');
    }

    if (!hasSetupFee(this.plan) && (!this.showFreeTrial() || this.isFreeTrialUnavailable())) {
      if (!this.hasCoupon() || this.isCouponForAllCycles()) {
        return this.t('payment.checkout-summary.total');
      }
    }

    if (
      hasSetupFee(this.plan) &&
      (!this.showFreeTrial() || this.isFreeTrialUnavailable()) &&
      this.isCouponForAllCycles() &&
      this.isFullyDiscounted()
    ) {
      return this.t('payment.checkout-summary.total');
    }

    if (
      !hasSetupFee(this.plan) &&
      this.isFreeTrialAvailable() &&
      this.hasCoupon() &&
      this.isCouponForAllCycles() &&
      this.isFullyDiscounted()
    ) {
      return this.t('payment.checkout-summary.total');
    }

    return this.t('payment.checkout-summary.total-today');
  }

  getNextPayment() {
    if (!this.isRecurring()) {
      return null;
    }

    if (hasSetupFee(this.plan)) {
      if (this.isFreeTrialAvailable()) {
        return { label: this.t('payment.checkout-summary.after-free-trial'), amount: this.getFormatedTotal(0) };
      }

      if (this.hasCoupon() && !this.isCouponForOneCycle() && !this.isCouponForAllCycles() && this.isFullyDiscounted()) {
        return null;
      }

      if (this.hasCoupon() && !this.isCouponForOneCycle() && !this.isCouponForAllCycles()) {
        return {
          label: this.t('payment.chekcout-summary.next-payment', { count: this.getPriceNumberOfCycles(1) }),
          amount: this.getFormatedTotal(1),
        };
      }

      return null;
    } else {
      if (!this.hasCoupon() || this.isCouponForAllCycles() || this.isFullyDiscounted()) {
        return null;
      }

      if (this.isCouponForOneCycle()) {
        if (this.isFreeTrialAvailable()) {
          return { label: this.t('payment.checkout-summary.after-free-trial'), amount: this.getFormatedTotal(0) };
        } else {
          return null;
        }
      }

      if (this.isFreeTrialAvailable()) {
        return {
          label: this.t('payment.chekcout-summary.next-payment', { count: this.getPriceNumberOfCycles(0) }),
          amount: this.getFormatedTotal(0),
        };
      } else {
        return {
          label: this.t('payment.chekcout-summary.next-payment', { count: this.getPriceNumberOfCycles(0) - 1 }),
          amount: this.getFormatedTotal(0),
        };
      }
    }
  }

  private getDisclaimerTranslationProps = () => {
    return {
      frequency: this.getPaymentFrequency(),
      firstTotal: this.getFormatedTotal(0),
      secondTotal: this.getFormatedTotal(1),
      thirdTotal: this.getFormatedTotal(2),
      firstDuration: this.getDisclaimerDuration(this.getSpannedDuration(0)),
      secondDuration: this.getDisclaimerDuration(this.getSpannedDuration(1)),
      thirdDuration: this.getDisclaimerDuration(this.getSpannedDuration(2)),
    };
  };

  private disclaimer() {
    const { frequency, firstTotal, firstDuration } = this.getDisclaimerTranslationProps();

    const translationProps = { price: firstTotal, frequency, duration: firstDuration };

    if (this.isFreeTrialAvailable()) {
      if (isUnlimitedPlan(this.plan)) {
        return this.t('payment.checkout-summary.payment-disclaimer-new.after-free-trial-unlimited', translationProps);
      } else {
        return this.t('payment.checkout-summary.payment-disclaimer-new.after-free-trial-limited', translationProps);
      }
    } else {
      if (isUnlimitedPlan(this.plan)) {
        return this.t('payment.checkout-summary.payment-disclaimer-new.unlimited', translationProps);
      } else {
        return this.t('payment.checkout-summary.payment-disclaimer-new.limited', translationProps);
      }
    }
  }

  private disclaimerWithCoupon() {
    const { frequency, firstTotal, secondTotal, firstDuration, secondDuration } = this.getDisclaimerTranslationProps();

    const firstTotalProps = {
      price: firstTotal,
      frequency,
      duration: firstDuration,
      nextPrice: secondTotal,
      nextDuration: secondDuration,
    };

    const secondTotalProps = { price: secondTotal, frequency, duration: secondDuration };

    if (this.isCouponForAllCycles()) {
      if (this.isFullyDiscounted()) {
        return null;
      }

      if (this.isFreeTrialAvailable()) {
        if (isUnlimitedPlan(this.plan)) {
          return this.t('payment.checkout-summary.payment-disclaimer-new.after-free-trial-unlimited', firstTotalProps);
        } else {
          return this.t('payment.checkout-summary.payment-disclaimer-new.after-free-trial-limited', firstTotalProps);
        }
      } else {
        if (isUnlimitedPlan(this.plan)) {
          return this.t('payment.checkout-summary.payment-disclaimer-new.unlimited', firstTotalProps);
        } else {
          return this.t('payment.checkout-summary.payment-disclaimer-new.limited', firstTotalProps);
        }
      }
    } else {
      if (this.isFullyDiscounted()) {
        const translationProps = { ...firstTotalProps, durationCount: this.getSpannedDuration(0) };
        if (isUnlimitedPlan(this.plan)) {
          return this.t(
            `payment.checkout-summary.payment-disclaimer-new.later-payments-with-coupons-unlimited-fully-discounted-${this.periodString(
              'Singular',
            )}`,
            translationProps,
          );
        } else {
          if (!this.hasCustomCycles() && this.getPriceNumberOfCycles(1) === 1) {
            return this.t(
              `payment.checkout-summary.payment-disclaimer-new.later-payments-with-coupons-limited-one-cycle-fully-discounted-${this.periodString(
                'Singular',
              )}`,
              translationProps,
            );
          } else {
            return this.t(
              `payment.checkout-summary.payment-disclaimer-new.later-payments-with-coupons-limited-fully-discounted-${this.periodString(
                'Singular',
              )}`,
              translationProps,
            );
          }
        }
      } else {
        if (isUnlimitedPlan(this.plan)) {
          return this.t('payment.checkout-summary.payment-disclaimer-new.later-payments-unlimited', secondTotalProps);
        } else {
          if (!this.hasCustomCycles() && this.getPriceNumberOfCycles(1) === 1) {
            return this.t(
              'payment.checkout-summary.payment-disclaimer-new.later-payments-limited-one-cycle',
              secondTotalProps,
            );
          } else {
            return this.t('payment.checkout-summary.payment-disclaimer-new.later-payments-limited', secondTotalProps);
          }
        }
      }
    }
  }

  private disclaimerWithSetupFee() {
    const { frequency, secondTotal, secondDuration } = this.getDisclaimerTranslationProps();

    const secondTotalProps = { price: secondTotal, frequency, duration: secondDuration };

    if (isUnlimitedPlan(this.plan)) {
      return this.t('payment.checkout-summary.payment-disclaimer-new.later-payments-unlimited', secondTotalProps);
    } else {
      if (!this.hasCustomCycles() && this.getPriceNumberOfCycles(1) === 1) {
        return this.t(
          'payment.checkout-summary.payment-disclaimer-new.later-payments-limited-one-cycle',
          secondTotalProps,
        );
      } else {
        return this.t('payment.checkout-summary.payment-disclaimer-new.later-payments-limited', secondTotalProps);
      }
    }
  }

  private disclaimerWithSetupFeeAndCoupon() {
    const { frequency, secondTotal, secondDuration, thirdTotal, thirdDuration } = this.getDisclaimerTranslationProps();

    const secondTotalProps = {
      price: secondTotal,
      frequency,
      duration: secondDuration,
      nextPrice: thirdTotal,
      nextDuration: thirdDuration,
    };

    const thirdTotalProps = {
      price: thirdTotal,
      frequency,
      duration: thirdDuration,
    };

    if (this.isCouponForAllCycles() && this.isFullyDiscounted()) {
      return null;
    }

    if (this.isCouponForOneCycle() || this.isCouponForAllCycles()) {
      if (isUnlimitedPlan(this.plan)) {
        return this.t('payment.checkout-summary.payment-disclaimer-new.later-payments-unlimited', secondTotalProps);
      } else {
        if (!this.hasCustomCycles() && this.getPriceNumberOfCycles(1) === 1) {
          return this.t(
            'payment.checkout-summary.payment-disclaimer-new.later-payments-limited-one-cycle',
            secondTotalProps,
          );
        } else {
          return this.t('payment.checkout-summary.payment-disclaimer-new.later-payments-limited', secondTotalProps);
        }
      }
    }

    if (this.isFullyDiscounted()) {
      const translationProps = { ...secondTotalProps, durationCount: this.getSpannedDuration(1) };
      if (isUnlimitedPlan(this.plan)) {
        return this.t(
          `payment.checkout-summary.payment-disclaimer-new.later-payments-with-coupons-unlimited-fully-discounted-${this.periodString(
            'Singular',
          )}`,
          translationProps,
        );
      }

      if (!this.hasCustomCycles() && this.getPriceNumberOfCycles(2) === 1) {
        return this.t(
          `payment.checkout-summary.payment-disclaimer-new.later-payments-with-coupons-limited-one-cycle-fully-discounted-${this.periodString(
            'Singular',
          )}`,
          translationProps,
        );
      } else {
        return this.t(
          `payment.checkout-summary.payment-disclaimer-new.later-payments-with-coupons-limited-fully-discounted-${this.periodString(
            'Singular',
          )}`,
          translationProps,
        );
      }
    }

    if (this.isFreeTrialAvailable()) {
      const hasOneCouponCycleLeft = this.getPriceNumberOfCycles(1) === 1;

      if (hasOneCouponCycleLeft) {
        const translationProps = { ...secondTotalProps, durationCount: this.getSpannedDuration(1) };

        if (isUnlimitedPlan(this.plan)) {
          return this.t(
            `payment.checkout-summary.payment-disclaimer-new.later-payments-with-one-coupon-cycle-unlimited-${this.periodString(
              'Singular',
            )}`,
            translationProps,
          );
        } else {
          if (!this.hasCustomCycles() && this.getPriceNumberOfCycles(2) === 1) {
            return this.t(
              `payment.checkout-summary.payment-disclaimer-new.later-payments-with-one-coupon-cycle-limited-one-cycle-${this.periodString(
                'Singular',
              )}`,
              translationProps,
            );
          } else {
            return this.t(
              `payment.checkout-summary.payment-disclaimer-new.later-payments-with-one-coupon-cycle-limited-${this.periodString(
                'Singular',
              )}`,
              translationProps,
            );
          }
        }
      }

      if (isUnlimitedPlan(this.plan)) {
        return this.t(
          'payment.checkout-summary.payment-disclaimer-new.later-payments-with-coupons-unlimited',
          secondTotalProps,
        );
      } else {
        if (!this.hasCustomCycles() && this.getPriceNumberOfCycles(2) === 1) {
          return this.t(
            'payment.checkout-summary.payment-disclaimer-new.later-payments-with-coupons-limited-one-cycle',
            secondTotalProps,
          );
        } else {
          return this.t(
            'payment.checkout-summary.payment-disclaimer-new.later-payments-with-coupons-limited',
            secondTotalProps,
          );
        }
      }
    } else {
      if (isUnlimitedPlan(this.plan)) {
        if (this.isFullyDiscounted()) {
          return this.t(
            'payment.checkout-summary.payment-disclaimer-new.later-payments-unlimited-fully-discounted',
            thirdTotalProps,
          );
        } else {
          return this.t('payment.checkout-summary.payment-disclaimer-new.later-payments-unlimited', thirdTotalProps);
        }
      } else {
        if (!this.hasCustomCycles() && this.getPriceNumberOfCycles(2) === 1) {
          if (this.isFullyDiscounted()) {
            return this.t(
              'payment.checkout-summary.payment-disclaimer-new.later-payments-limited-one-cycle-fully-discounted',
              thirdTotalProps,
            );
          } else {
            return this.t(
              'payment.checkout-summary.payment-disclaimer-new.later-payments-limited-one-cycle',
              thirdTotalProps,
            );
          }
        } else {
          if (this.isFullyDiscounted()) {
            return this.t(
              'payment.checkout-summary.payment-disclaimer-new.later-payments-limited-fully-discounted',
              thirdTotalProps,
            );
          } else {
            return this.t('payment.checkout-summary.payment-disclaimer-new.later-payments-limited', thirdTotalProps);
          }
        }
      }
    }
  }

  private getSpannedDuration(durationIndex: number) {
    const cycleCount = this.prices[durationIndex]?.duration?.numberOfCycles ?? 1;

    return (this.getCycleLength() ?? 1) * cycleCount;
  }

  private getDisclaimerDuration(count: number | undefined) {
    return this.t(`payment.checkout-summary.payment-disclaimer.duration-${this.periodString('Singular')}`, { count });
  }

  getPaymentDisclaimer(): string | null {
    if (!this.isRecurring()) {
      return null;
    }

    if (hasSetupFee(this.plan)) {
      if (this.hasCoupon()) {
        return this.disclaimerWithSetupFeeAndCoupon();
      } else {
        return this.disclaimerWithSetupFee();
      }
    } else {
      if (this.hasCoupon()) {
        return this.disclaimerWithCoupon();
      } else {
        return this.disclaimer();
      }
    }
  }
}
