import React, { useState } from 'react';
import { Checkbox, Dialog, TextButton, Text } from 'wix-ui-tpa/cssVars';
import { uouActionAtCheckout } from '@wix/bi-logger-pricing-plans-data/v2';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import { Trans, useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { CheckoutAction } from '../../../../../utils/bi';
import { classes, st } from './TermsAndConditionsCheckbox.st.css';

interface TermsAndConditionsCheckboxProps {
  termsAndConditions: string;
  value: boolean;
  onChange: (value: boolean) => void;
  shouldDisplayError: boolean;
  isNarrow: boolean;
  planId: string | undefined;
}

export const TermsAndConditionsCheckbox: React.FC<TermsAndConditionsCheckboxProps> = ({
  termsAndConditions,
  value,
  onChange,
  shouldDisplayError,
  isNarrow,
  planId,
}) => {
  const bi = useBi();
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    disableDocumentScroll();
    setIsDialogOpen(true);
    bi.report(uouActionAtCheckout({ action: CheckoutAction.OpenTermsAndConditionsModal, planGuid: planId }));
  };

  return (
    <div className={st(classes.root, { narrow: isNarrow })}>
      <Checkbox
        data-hook={VIEWER_HOOKS.CHECKOUT_TERMS_AND_CONDITIONS_CHECKBOX}
        className={classes.termsAndConditionsCheckbox}
        checked={value}
        label={
          <Trans
            i18nKey="payment.terms-and-conditions.label"
            components={{
              button: (
                <TextButton
                  className={classes.openModalButton}
                  data-hook={VIEWER_HOOKS.CHECKOUT_TERMS_AND_CONDITIONS_CHECKBOX_TEXT_BUTTON}
                  onClick={openDialog}
                />
              ),
            }}
          />
        }
        error={!value && shouldDisplayError}
        newErrorMessage
        errorMessage={t('payment.terms-and-conditions.error-message')}
        onChange={() => {
          onChange(!value);
        }}
      />
      <Dialog
        isOpen={isDialogOpen}
        onClose={() => {
          enableDocumentScroll();
          setIsDialogOpen(false);
        }}
        notFullscreenOnMobile={true}
        contentClassName={classes.modalContent}
        childrenWrapperClassName={classes.modalContentWrapper}
        appendToBody
      >
        <div
          className={st(classes.modal, { narrow: isNarrow })}
          data-hook={VIEWER_HOOKS.CHECKOUT_TERMS_AND_CONDITIONS_MODAL}
        >
          <Text className={st(classes.modalTitle, { narrow: isNarrow })}>
            {t('payment.terms-and-conditions.modal-title')}
          </Text>
          <div className={classes.termsAndConditionsWrapper}>
            <Text className={st(classes.termsAndConditions, { narrow: isNarrow })}>{termsAndConditions}</Text>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

function disableDocumentScroll() {
  document.body.style.overflow = 'hidden';
}

function enableDocumentScroll() {
  document.body.style.removeProperty('overflow');
}
