import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { LockFill } from '@wix/wix-ui-icons-common/on-stage';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { classes } from './SecureCheckoutBadge.st.css';

export const SecureCheckoutBadge = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.root} data-hook="secure-checkout-badge">
      <LockFill /> <Text className={classes.text}>{t('payment.secure-checkout')}</Text>
    </div>
  );
};
