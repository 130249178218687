import * as React from 'react';

export interface InputRegistry {
  add(field: HTMLInputElement): void;
  remove(field: HTMLInputElement): void;
  focus(): void;
  isValid(): boolean;
}

export const StartDateInputRegistryContext = React.createContext<InputRegistry>({
  add() {},
  focus() {},
  isValid() {
    return false;
  },
  remove() {},
});

export const StartDateInputRegistryProvider = StartDateInputRegistryContext.Provider;

export function useStartDateInputs() {
  return React.useContext(StartDateInputRegistryContext);
}

export const createDefaultInputRegistry = (): InputRegistry => {
  const fields: HTMLInputElement[] = [];

  return {
    add(field: HTMLInputElement) {
      fields.push(field);
    },
    remove(field: HTMLInputElement) {
      const index = fields.indexOf(field);
      if (index > -1) {
        fields.splice(index, 1);
      }
    },
    focus() {
      for (const field of fields) {
        const { height } = field.getBoundingClientRect();
        if (height > 0) {
          field.focus();
          field.scrollIntoView();
          break;
        }
      }
    },
    isValid() {
      for (const field of fields) {
        if (!field.validity.valid) {
          return false;
        }
      }
      return true;
    },
  };
};
