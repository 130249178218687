export enum Step {
  Login = 'login',
  CustomerInfo = 'customerInfo',
  AdditionalInfo = 'additionalInfo',
  Payment = 'payment',
}

export type Steps = Step[];

export type StepsState = {
  [K in Step]: 'pending' | 'active' | 'completed';
};

export interface StepProps {
  position: number;
  onCompleted: () => void;
  onApiSet: (api: StepAPI) => void;
  setActive: () => void;
  isActiveStep: boolean;
  isPendingStep: boolean;
  isCompletedStep: boolean;
  isLastStep: boolean;
  isNarrow: boolean;
  state: 'pending' | 'active' | 'completed';
}

export interface StepAPI {
  execute: () => Promise<void>;
  isValid: () => boolean;
}

export type ApiRegistry = Map<Step, StepAPI>;
