import React from 'react';
import { Button, Dialog, Text, TextTypography } from 'wix-ui-tpa/cssVars';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { AlertModal as AlertModalProps } from '../../../../types/common';
import { alertModalContent } from '../../../../utils/alert-modal-content';
import { classes } from './index.st.css';

export const AlertModal: React.FC<AlertModalProps> = (modal) => {
  const { t } = useTranslation();
  const { onClose } = modal;
  const { title, subtitle, buttonText } = alertModalContent(t, modal);

  return (
    <Dialog data-hook={VIEWER_HOOKS.ALERT_MODAL} isOpen={true} onClose={() => onClose()}>
      <InnerAlertModal title={title} subtitle={subtitle} buttonText={buttonText} onClose={onClose} />
    </Dialog>
  );
};
interface InnerAlertModalProps {
  title: string;
  subtitle: string;
  buttonText: string;
  onClose: () => void;
}
export const InnerAlertModal = ({ title, subtitle, buttonText, onClose }: InnerAlertModalProps) => {
  return (
    <div className={classes.wrapper}>
      <Text data-hook={VIEWER_HOOKS.ALERT_MODAL_TITLE} typography={TextTypography.largeTitle} className={classes.title}>
        {title}
      </Text>
      <Text
        data-hook={VIEWER_HOOKS.ALERT_MODAL_SUBTITLE}
        typography={TextTypography.runningText}
        className={classes.subtitle}
      >
        {subtitle}
      </Text>
      <div>
        <Button
          data-hook={VIEWER_HOOKS.ALERT_MODAL_BUTTON}
          className={classes.button}
          upgrade
          onClick={() => onClose()}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
