import React from 'react';
import { Text, Divider } from 'wix-ui-tpa/cssVars';
import { getSetupFeeName, hasSetupFee, isFreePlan } from '@wix/pricing-plans-utils';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { getOrderCoupon, getPricesCoupon, hasOrderCoupon } from '../../../../../utils/domainUtils';
import { isStartDateCustomizable } from '../../../../../utils/plan';
import { CouponInput } from '../CouponInput';
import { classes, st } from './Big.st.css';
import { DateInput } from './DateInput';
import { SummaryProps } from './types';
import { useOrderSummary } from './useOrderSummary';
import { usePresenter } from './usePresenter';

export const BigSummary: React.FC<SummaryProps> = ({
  plan,
  prices = [],
  benefits,
  order,
  startDate,
  setStartDate,
  minStartDate,
  maxStartDate,
  isStartDateDisabled,
  updatePriceDetails,
  updatePriceDetailsError,
  couponInputMode,
  couponLoading,
  couponCode,
  removeCoupon,
  applyCouponError,
  hasCoupons,
}) => {
  const { t } = useTranslation();
  const presenter = usePresenter({ plan, prices, benefits, order });
  const orderSummary = useOrderSummary(plan, prices, order);
  const { total, subtotal, taxAmount, couponAmount, planPrice, setupFee } = orderSummary;

  const isCouponsEnabled = hasCoupons && !isFreePlan(plan);
  const hasCouponOnOrder = order && hasOrderCoupon(order);
  const couponId = hasCouponOnOrder ? getOrderCoupon(order!)?.id : getPricesCoupon(prices)?.id;

  const paymentDisclaimer = presenter.getPaymentDisclaimer();
  const nextPayment = presenter.getNextPayment();

  return (
    <div className={classes.box}>
      <div className={classes.section}>
        <Text className={classes.heading} tagName="h2" data-hook="payment-checkout-summary-title">
          {t('payment.checkout-summary.heading')}
        </Text>
        {isStartDateCustomizable(plan) ? (
          <DateInput
            label={t('payment.checkout-summary.start-date')}
            disabled={isStartDateDisabled}
            dataHook="start-date-input"
            value={startDate}
            max={maxStartDate}
            min={minStartDate}
            onChange={setStartDate}
          />
        ) : null}
      </div>
      <div className={classes.sectionNarrow}>
        <div className={st(classes.entry, { noWrap: true })}>
          <Text className={classes.labelBold} data-hook="payment-checkout-summary-plan-name">
            {presenter.getPlanName()}
          </Text>
          <Text className={classes.value}>{planPrice}</Text>
        </div>
        <Text className={classes.textLight} data-hook="payment-checkout-summary-plan-duration">
          {presenter.getDurationFormatted()}
        </Text>
        {presenter.showFreeTrial() && (
          <Text className={classes.textLight} data-hook="payment-checkout-summary-plan-free-trial">
            {presenter.getFreeTrialFormatted()}
          </Text>
        )}
        {presenter.hasSessions() && (
          <Text className={classes.textLight} data-hook="payment-checkout-summary-plan-sessions">
            {presenter.getSessionFormatted()}
          </Text>
        )}
      </div>
      {hasSetupFee(plan) && (
        <div className={classes.section}>
          <div className={classes.entry}>
            <Text className={classes.labelBold}>{getSetupFeeName(plan)}</Text>
            <Text className={classes.value}>{setupFee}</Text>
          </div>
        </div>
      )}
      {isCouponsEnabled ? (
        <>
          <Divider className={classes.divider} />
          <CouponInput
            disabled={hasCouponOnOrder || isStartDateDisabled}
            mode={hasCouponOnOrder ? 'coupon' : couponInputMode}
            error={updatePriceDetailsError ?? applyCouponError}
            value={hasCouponOnOrder ? getOrderCoupon(order!)!.code : couponCode}
            onApplyClick={(code) => updatePriceDetails(plan.id!, code)}
            onRemoveClick={() => removeCoupon(plan.id!, couponId!)}
            loading={couponLoading}
          />
        </>
      ) : null}
      {((taxAmount || presenter.hasCoupon()) && subtotal) ||
      (presenter.hasCoupon() && subtotal) ||
      (taxAmount && subtotal) ? (
        <>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            {(taxAmount || presenter.hasCoupon()) && subtotal ? (
              <div className={classes.entry} data-hook="payment-checkout-summary-subtotal-row">
                <Text className={classes.label}>{t('payment.checkout-summary.subtotal')}</Text>
                <Text className={classes.value} data-hook="payment-checkout-summary-subtotal">
                  {subtotal}
                </Text>
              </div>
            ) : null}
            {presenter.hasCoupon() && subtotal ? (
              <div className={classes.entry} data-hook="payment-checkout-summary-coupon-discount-row">
                <Text className={classes.label}>{t('payment.checkout-summary.coupon-code')}</Text>
                <Text className={classes.value} data-hook="payment-checkout-summary-coupon-discount">
                  {couponAmount}
                </Text>
              </div>
            ) : null}
            {taxAmount && subtotal ? (
              <div className={classes.entry} data-hook="payment-checkout-summary-tax-row">
                <Text className={classes.label} data-hook="payment-checkout-summary-tax-label">
                  {presenter.getTaxName()} ({presenter.getTaxRate()} %)
                </Text>
                <Text className={classes.value} data-hook="payment-checkout-summary-tax">
                  {taxAmount}
                </Text>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <div className={classes.entry}>
          <Text className={st(classes.labelLarge)} data-hook="payment-checkout-summary-plan-total">
            {presenter.getTotalLabel()}
          </Text>
          <Text className={st(classes.valueLarge)} data-hook="payment-checkout-summary-plan-price">
            {total}
          </Text>
        </div>
        {presenter.showPaymentPeriod() ? (
          <div className={classes.paymentPeriodEntry} data-hook="payment-checkout-summary-payment-period">
            <Text className={classes.paymentPeriod}>{presenter.getPaymentPeriod()}</Text>
          </div>
        ) : null}
        {nextPayment && (
          <div className={classes.entry}>
            <Text className={classes.label}>{nextPayment.label}</Text>
            <Text className={classes.value}>{nextPayment.amount}</Text>
          </div>
        )}
        {paymentDisclaimer ? (
          <div className={classes.entry} data-hook="payment-checkout-summary-payment-disclaimer">
            <Text className={classes.value}>{paymentDisclaimer}</Text>
          </div>
        ) : null}
      </div>
    </div>
  );
};
