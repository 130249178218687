import { useMemo } from 'react';
import { noop } from 'lodash';

const noopConsole: typeof console = {
  ...console,
  log: noop,
  error: noop,
  debug: noop,
  warn: noop,
  assert: noop,
  clear: noop,
  count: noop,
  countReset: noop,
  dir: noop,
  dirxml: noop,
  group: noop,
  groupEnd: noop,
  groupCollapsed: noop,
  info: noop,
  profile: noop,
  profileEnd: noop,
  table: noop,
  time: noop,
  timeEnd: noop,
  timeStamp: noop,
  timeLog: noop,
  trace: noop,
};

export const useLogger = () =>
  useMemo(() => {
    try {
      const debug = new URLSearchParams(window.location.search).get('debug') ?? false;
      if (debug) {
        return console;
      }
    } catch (e) {}
    return noopConsole;
  }, []);
