import React from 'react';

interface MultilingualCountryCodeContext {
  multilingualCountryCode: string | undefined;
}

const MultilingualCountryCodeContainer = React.createContext<MultilingualCountryCodeContext>({
  multilingualCountryCode: undefined,
});

interface MultilingualCountryCodeProviderProps extends MultilingualCountryCodeContext {
  children: React.ReactNode;
}

export const MultilingualCountryCodeProvider: React.FC<MultilingualCountryCodeProviderProps> = ({
  children,
  multilingualCountryCode,
}) => {
  return (
    <MultilingualCountryCodeContainer.Provider value={{ multilingualCountryCode }}>
      {children}
    </MultilingualCountryCodeContainer.Provider>
  );
};

export const useMultilingualCountryCode = () => React.useContext(MultilingualCountryCodeContainer);
